<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <div class="text-center">
        <v-card class="rounded-xl  pa-4 elevation-6 justify-center" width="280">
          <v-card-title color="transparent" class="justify-center" flat>
            <v-img
              src="@/assets/logotext.png"
              transition="scale-transition"
              height="80"
              contain
            />
          </v-card-title>
          <v-card-text>
            <h3 class="my-8 text-center font-weight-bold">АВТОРИЗАЦИЯ</h3>

            <v-form @submit.prevent="login(inputEmail, inputPassword)">
              <v-text-field
                id="email"
                label="e-mail"
                name="email"
                append-icon="mdi-email"
                type="email"
                color="primary"
                v-model="inputEmail"
              />

              <v-text-field
                id="password"
                label="password"
                name="password"
                append-icon="mdi-lock"
                type="password"
                color="primary"
                v-model="inputPassword"
              />
              <v-card-actions class="justify-center">
                <v-btn
                  type="submit"
                  color="rounded-pill"
                  class="font-weight-bold"
                  >Войти
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
        <a href="https://eco-monitor.kz/">
          <v-btn class="ma-5" rounded color="primary" dark>На портал</v-btn>
        </a>
      </div>
    </v-row>
    <v-card color="warning">
      <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginScreen",

  data: () => ({
    inputEmail: "",
    inputPassword: "",
    snackbar: false,
    text: "",
    timeout: 2000
  }),

  methods: {
    ...mapActions(["loginUser"]),
    async login(login, password) {
      await this.loginUser({ email: login, password: password });
      this.text = this.$store.state.message.msg;
      this.snackbar = true;
    }
  },
  mounted() {
    const theme = localStorage.getItem("useDarkTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else this.$vuetify.theme.dark = false;
    }
  }
};
</script>

<style scoped>
.bg {
  background: #75a4ff;
  background: -webkit-linear-gradient(to bottom, #75a4ff, #234b83);
  background: linear-gradient(to bottom, #75a4ff, #234b83);
}
</style>
